.sele_pru{
  padding: 0 !important
}
input:focus, textarea:focus, select:focus,.select2-container-active, div.select2-input {
	background: #5DBAFF !important;
	color: #000 !important;
	font-weight: 600 !important;
	border: 2px solid #0278FE !important;
}
.my-swal {
  z-index: 999999 !important;
}
input, textarea{
	text-transform: uppercase !important;
}
.tabla-comisiones .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background: #5DBAFF !important;
}
/**button:focus  {
	outline: none !important;
	box-shadow: 0 0 0 5px rgba(21, 156, 228, 0.4) !important;
}**/